<style lang="scss">

.page {
  padding: 30px;
  margin-top: 50px;
  margin-bottom: 30px;

  .page-title {
    border-left: #f7b02a 6px solid;
    margin: 0 0 30px 0px;
    padding: 10px 0px 10px 10px;

    h2 {
      font-size: 30px;
      font-weight: 700;
      margin: 5px;
    }

    .time {
      float: right;
      color: #999
    }
  }

  .page-content {
    line-height: 1.6;
    font-size: 16px;
    color: #666;

    p {
      text-align: justify;
    }

    blockquote {
      margin: 0;
      padding: 15px 25px 15px 55px;
      background: #ddd url("../assets/icon_quote.png") no-repeat 3px 0;
      border-radius: 3px;
      color: #555;
    }

    video {
      margin: 0 auto;
      width: auto;
      max-width: 100%;
    }

    img {
      max-width: 100%;
    }

  }
}


@media only screen and (max-width: 641px) {
  .page {
    padding: 20px;
    margin-top: 10px;

    .page-title {
      margin: 0 0 30px 0;
    }
  }
}

</style>

<template>

  <div class="container">
    <div class="page">
      <div class="page-title">
        <h2>{{ title }}</h2>
<!--<div class="time">{{ createTime }}</div>-->
      </div>
      <div class="page-content" v-html="content"></div>
    </div>
  </div>

</template>
<script>
// import Waterfall from "../components/waterfall";

import { ShareSet} from '../utils/share'   // 记得改为你的路径

export default {
  name: "PageAuth",
  components: {
    // Waterfall
  },
  data() {
    return {
      title: "",
      createTime: "",
      content: ""
    };
  },
  beforeMount() {
    window.addEventListener('resize', this.resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  created: function () {
    this.resizeHandler();
    // console.log(this.$route.params);
    var that = this;
    let token = window.localStorage.getItem("token");
    if(token){
    this.$http.post(
        '/index/article/info',
        {
          id: this.$route.params.id
        },{
          headers: {
            'token': token
          }
        })
        .then(function (response) {
          console.log(response);
          //this.loading = false;
          that.title = response.data.title;
          that.createTime = response.data.modify_time;
          that.content = response.data.content;

          // reset Share
          var titleName = {
            'home': "公司介绍",
            'solution': "解决方案",
            'case': "精彩案例",
            'industry': "行业精选",
            'contact': "联系我们",
            'members': "会员专区",
          }
          let shareTitle = '奇蛋科技';
          for (let key in titleName) {
            if (that.$route.path.indexOf(key) == 1) {
              shareTitle = '奇蛋科技 - ' + titleName[key];
            }
          }
          ShareSet({
            shareTitle: shareTitle,
            shareDesc: response.data.title,
            shareUrl: location.href,
            shareImg: response.data.image,
          });

        })
        .catch(function (error) {
          console.log(error);
        });
    }else{
      let that = this;
      that.$store.commit('updateToken', null);
      window.localStorage.removeItem("token");
      that.$router.push(`/members/login`);
    }
    //*/
  },
  computed: {
    // itemWidth() {
    //   return 133 * 0.5 * (document.documentElement.clientWidth / 375);
    // },
    // gutterWidth() {
    //   return 10 * 0.5 * (document.documentElement.clientWidth / 375);
    // }
  },
  methods: {
    resizeHandler() {
      // if (document.documentElement.clientWidth < 640) {
      //   this.col = 2;
      // } else if (document.documentElement.clientWidth < 990) {
      //   this.col = 3;
      // } else {
      //   this.col = 4;
      // }
      // console.log("resizeHandler", this.col);
    }
  },
  mounted() {
    //this.data = this.originData;
  }
};
</script>
